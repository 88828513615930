<template>
  <div>

    <div class="bg_box">
      <div v-if="!isShowPage">
        <div class="loading">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>

    <div class="personbox" v-if="isShowPage">
            <!-- 我的 -->
        <van-nav-bar
            title="我的"
            safe-area-inset-top
            fixed
            placeholder
            :border="false"
        >
          <template #right>
            <img src="@/assets/images/icon_settings_red_4.png"  alt="" @click="loginout">
          </template>
        </van-nav-bar>
      <!-- 顶部姓名 -->
      <div class="rel">
        <div class="phonttop">
        </div>
        <!-- 顶部 用户信息-->
        <div class="abs topbox" v-if="processEnv">
          <div class="flex">
            <div class="imgbox">
              <template v-if="uerinfo.avatar">
                <img :src="uerinfo.avatar" alt="" />
              </template>
              <template v-else>
                <template v-if="uerinfo.realName">
                  <img src="@/assets/images/pic_head portrait_com@2x.png" alt="" />
                </template>
                <template v-else>

                  <img src="@/assets/images/pic_head portrait_com@2x.png" alt=""  v-if="uerinfo.auth == 'UNPOST'" />
                  <img
                    src="@/assets/images/pic_head portrait_com@2x.png"
                    alt=""
                    v-if="uerinfo.auth == 'POST'"
                  />
                </template>
              </template>
            </div>
            <div class="namebox">
              <div>
                <div class="postBox">
                  <p v-if="uerinfo.realName">{{ uerinfo.realName }}</p>
                  <div v-if="uerinfo.auth == 'UNPOST' || uerinfo.auth == 'FAIL'" data-info="certification" @click="isAutoGraph">
                    <div class="pleaseCertify">
                      <img src="@/assets/images/btn_authentication@2x.png" alt="">
                    </div>
                  </div>
                  <div v-else-if="uerinfo.auth == 'POST'">
                    <div class="pleaseCertify">
                      <img src="@/assets/images/btn_examine@2x.png" alt="">
                    </div>
                  </div>
                  <div v-else-if="uerinfo.auth == 'DONE'">
                    <div class="pleaseCertify" v-if="!uerinfo.hasOwnProperty('sign')">
                      <router-link :to="{ name: 'AccountContract', query:{pricing:true}}">
                        <img src="@/assets/images/btn_post_examine@2x.png" alt="">
                      </router-link>
                    </div>
                    <div class="pleaseCertify" v-else-if="!uerinfo.hasOwnProperty('faceVerify')">
                      <router-link :to="{ name: 'face' }">
                        <img src="@/assets/images/btn_post_examine@2x.png" alt="">
                      </router-link>
                    </div>
                    <div class="pleaseCertify" v-else>
                      <img src="@/assets/images/btn_post_examine@2x.png" alt="">
                    </div>
                  </div>
                </div>

              </div>
              <p class="phone">{{ uerinfo.phone }}</p>
            </div>
          </div>
          <div class="waitHandleBox flex-between">
            <div class="waitHandleDescribe">待处理</div>
            <div class="waitHandle" @click="openBuy">
              <div class="number">{{ buyMaterials ? buyMaterials : 0 }}</div>
              <div>买料订单</div>
            </div>
            <div class="waitHandle" @click="openSell">
              <div class="number">{{ sellMaterials ? sellMaterials : 0 }}</div>
              <div>卖料订单</div>
            </div>
            <div class="waitHandle" @click="openMortgage">
              <div class="number">{{ mortgageMaterials ? mortgageMaterials : 0 }}</div>
              <div>存料订单</div>
            </div>
          </div>
        </div>
        <div class="flex abs topbox" v-if="!processEnv">
          <div class="imgbox">
            <template v-if="uerinfo.avatar">
              <img :src="uerinfo.avatar" alt="" />
            </template>
            <template v-else>
              <template v-if="uerinfo.realName">
                <img src="@/assets/images/jqt.png" alt="" />
              </template>
              <template v-else>

                <img src="@/assets/images/jqt.png" alt=""  v-if="uerinfo.auth == 'UNPOST'" />
                <img
                  src="@/assets/images/jqt.png"
                  alt=""
                  v-if="uerinfo.auth == 'POST'"
                />
              </template>
            </template>
          </div>
          <div class="namebox">
            <div>
              <div class="postBox">
                <p v-if="uerinfo.realName">{{ uerinfo.realName }}</p>
                <div v-if="uerinfo.auth == 'UNPOST' || uerinfo.auth == 'FAIL'" data-info="certification" @click="isAutoGraph">
                  <div class="pleaseCertify">
                    <img src="@/assets/images/btn_authentication@2x.png" alt="">
                  </div>
                </div>
                <div v-else-if="uerinfo.auth == 'POST'">
                  <div class="pleaseCertify">
                    <img src="@/assets/images/btn_examine@2x.png" alt="">
                  </div>
                </div>
                <div v-else-if="uerinfo.auth == 'DONE'">
                  <div class="pleaseCertify" v-if="!uerinfo.hasOwnProperty('sign')">
                    <router-link :to="{ name: 'AccountContract', query:{pricing:true}}">
                      <img src="@/assets/images/btn_post_examine@2x.png" alt="">
                    </router-link>
                  </div>
                  <div class="pleaseCertify" v-if="!uerinfo.hasOwnProperty('faceVerify')">
                    <router-link :to="{ name: 'face' }">
                      <img src="@/assets/images/btn_post_examine@2x.png" alt="">
                    </router-link>
                  </div>
                  <div class="pleaseCertify" v-else>
                    <img src="@/assets/images/btn_post_examine@2x.png" alt="">
                  </div>
                </div>
              </div>

            </div>
            <p class="phone">{{ uerinfo.phone }}</p>
          </div>
        </div>
      </div>
      <!-- 主体内容 -->
      <div class="topcenter">
            <!-- 常用功能盒子 -->
            <div class="box">
                    <h1>常用功能</h1>
                    <div class="flex">
                            <!-- 图标盒子 -->
                         <div class="iconBox" @click="linkFunciton(0)">
                             <div class="imgminbox">
                                    <img src="@/assets/images/icon_person_sell_red@2x.png" alt="">
                             </div>
                             <span>卖料订单</span>
                         </div>
                         <div class="iconBox" @click="linkFunciton(1)">
                             <div class="imgminbox">
                                    <img src="@/assets/images/icon_person_buy_red@2x.png" alt="">
                             </div>
                             <span>买料订单</span>
                         </div>
                      <div class="iconBox" @click="linkFunciton(5)">
                        <div class="imgminbox">
                          <img src="@/assets/images/icon_person_changeMaterial_red@2x.png" alt="">
                        </div>
                        <span>换料订单</span>
                      </div>
                        <div class="iconBox" @click="linkFunciton(2)">
                          <div class="imgminbox">
                          <img src="@/assets/images/icon_person_stock_blue@2x.png" alt="">
                        </div>
                        <span>存料订单</span>
                      </div>
                    </div>
                    <div class="flex">
                      <div class="iconBox"  @click="linkFunciton(4)">
                        <div class="imgminbox">
                          <img src="@/assets/images/icon_person_material_blue@2x.png" alt="">
                        </div>
                        <span>提料管理</span>
                      </div>
                      <div class="iconBox" @click="linkFunciton(7)">
                        <div class="imgminbox">
                          <img src="@/assets/images/icon_person_incoming_blue@2x.png" alt="">
                        </div>
                        <span>来料管理</span>
                      </div>
                      <div class="iconBox" @click="linkFunciton(8)">
                        <div class="imgminbox">
                          <img src="@/assets/images/icon_person_storeroom_yellow@2x.png" alt="">
                        </div>
                        <span>小金库</span>
                      </div>
                      <div class="iconBox" @click="linkFunciton(3)">
                        <div class="imgminbox">
                          <img src="@/assets/images/icon_person_deposit_yellow@2x.png" alt="">
                        </div>
                        <span>押金</span>
                      </div>
                    </div>
              <div class="flex">
                <div class="iconBox" @click="linkFunciton(6)">
                  <div class="imgminbox">
                    <img src="@/assets/images/icon_person_orderData_yellow@2x.png" alt="">
                  </div>
                  <span>订单报表</span>
                </div>
                <div class="iconBox">
                </div>
                <div class="iconBox">
                </div>
                <div class="iconBox">
                </div>
              </div>
            </div>
        <!-- 功能列表 -->
        <div class="box">
          <h1>其他功能</h1>
          <div class="flex">
            <!-- 图标盒子 -->
            <div class="iconBox" @click="link(8)">
              <div class="imgminbox">
                <img src="@/assets/images/icon_person_remind_red@2x.png" alt="">
              </div>
              <span>价格提醒</span>
            </div>
            <div class="iconBox" @click="link(0)">
              <div class="imgminbox">
                <img src="@/assets/images/icon_person_express_red@2x.png" alt="">
              </div>
              <span>快递查询</span>
            </div>
            <div class="iconBox" @click="link(1)">
              <div class="imgminbox">
                <img src="@/assets/images/icon_person_insured_red@2x.png" alt="">
              </div>
              <span>货运保单</span>
            </div>
            <div class="iconBox" @click="link(10)">
              <div class="imgminbox">
                <img src="@/assets/images/icon_person_integral_red@2x.png" alt="">
              </div>
              <span>我的积分</span>
            </div>
          </div>
          <div class="flex">
            <!-- 图标盒子 -->
            <div class="iconBox" @click="link(11)">
              <div class="imgminbox">
                <img src="@/assets/images/icon_person_pickup_red@2x.png" alt="">
              </div>
              <span>快递报单</span>
            </div>
            <div class="iconBox">
            </div>
            <div class="iconBox">
            </div>
            <div class="iconBox">
            </div>
          </div>
        </div>
        <!-- 功能列表 -->
        <div class="box">
          <h1>其他功能</h1>
          <div class="flex">
            <div class="iconBox" @click="link(6)">
              <div class="imgminbox">
                <img src="@/assets/images/icon_person_business_grey@2x.png" alt="">
              </div>
              <span>业务说明</span>
            </div>
            <div class="iconBox" @click="link(2)">
              <div class="imgminbox">
                <img src="@/assets/images/icon_person_condition_grey@2x.png" alt="">
              </div>
              <span>回收标准</span>
            </div>
            <div class="iconBox" @click="link(3)">
              <div class="imgminbox">
                <img src="@/assets/images/icon_person_bankCard_grey@2x.png" alt="">
              </div>
              <span>银行账号</span>
            </div>
            <div class="iconBox" @click="link(4)">
              <div class="imgminbox">
                <img src="@/assets/images/icon_person_wenhao_grey@2x.png" alt="">
              </div>
              <span>常见问题</span>
            </div>
          </div>
          <div class="flex">
            <div class="iconBox" @click="link(9)">
              <div class="imgminbox">
                <img src="@/assets/images/icon_person_inviteFriends_grey@2x.png" alt="">
              </div>
              <span>邀请好友</span>
            </div>
            <div class="iconBox" @click="link(7)">
              <div class="imgminbox">
                <img src="@/assets/images/icon_person_about_grey@2x.png" alt="">
              </div>
              <span>关于我们</span>
            </div>
            <div class="iconBox" @click="link(5)">
              <div class="imgminbox">
                <img src="@/assets/images/icon_person_online_grey@2x.png" alt="">
              </div>
              <span>联系客服</span>
            </div>
            <div class="iconBox">
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-dialog v-model="cershow">
      <div class="title">提示</div>
      <div class="centerbox_dialog">您的账号还未认证，暂无此项权限</div>
      <div class="gocer flex-around">
        <div @click="cershow = false" class="canclebox">取消</div>
        <router-link :to="{ name: 'certification' }">去认证</router-link>
      </div>
    </van-dialog>

    <van-dialog v-model="ceringModel">
      <div class="title">提示</div>
      <div class="centerbox_dialog">您的认证信息正在审核中，请稍后查看</div>
      <div class="gocer" @click="ceringModel = false">确定</div>
    </van-dialog>

    <van-dialog v-model="showOtherPickUp" :show-confirm-button="false">
      <div class="title">新增物流信息</div>
      <div class="centerbox_dialog">
        <div class="flex orderInfo topCol">
          <van-field
            v-model="OtherPickUpForm.expressNo"
            label="快递单号"
            placeholder="请输入快递单号"
            input-align="right"
            size="24px"
            type="text"
          />
        </div>
        <div class="flex orderInfo topCol">
          <van-field
            v-model="OtherPickUpForm.totalWeightGram"
            label="重量"
            placeholder="请输入货品克重"
            input-align="right"
            size="24px"
            type="number"
          />
        </div>
        <div class="flex orderInfo topCol">
          <van-field
            v-model="OtherPickUpForm.expressCompany"
            label="快递公司"
            placeholder="请输入快递公司"
            input-align="right"
            size="24px"
            type="text"
          />
        </div>
        <div class="flex orderInfo topCol">
          <van-field
            v-model="OtherPickUpForm.phone"
            label="手机尾号"
            placeholder="收件人或寄件人手机尾号后四位"
            input-align="right"
            size="24px"
            type="number"
          />
        </div>
        <div class="flex orderInfo topCol">
          <van-field
            v-model="OtherPickUpForm.remark"
            label="备注"
            placeholder="请输入备注"
            input-align="right"
            size="24px"
            type="textarea"
          />
        </div>
        <div class="tips">
          注：不填写收件人或者寄件人手机尾号后四位将影响物流查询
        </div>
      </div>
      <div class="flex-between popbtn">
        <div class="gocer closebtn" @click="close" >取消</div>
        <div class="gocer confirmbtn" @click="confirm">确定</div>
      </div>
    </van-dialog>

  </div>
</template>
<script>
import * as JieSureWattingMoney from "@/utils/api";
import router from "@/router";
import { Toast } from "vant";
export default {
  data() {
    return {
      isShowPage: false,
      // 是否认证
      realnameBoolean: false,
      // 认证是否通过(审核中)
      underReview:false,
      ceringModel: false,
      cershow: false,

      myMoney: "0.00",
      moible: "--",
      // 用户信息
      uerinfo: "",
      // 初始化克重
      weight_init: "",
      // 卖料订单待处理的数据
      sellMaterials:'',
      // 买料订单待处理的数据
      buyMaterials:'',
      mortgageMaterials: "",
      // 人脸未认证时出现的盒子
      isFaceAuthBox:false,
      // 跳转路由信息
      linkList:['pickUp','claimedit','recovery','bank','commonProblem','kefu','BusinessDescription','aboutList', 'priceAlert', 'inviteFriends', 'integral'],
      // 常用功能路由信息  卖料订单路径   存料订单路径   押金订单路径
      commonFunctionsList:['sellSettlement','buyOrder', 'keepRecord','pricingDeposit', 'materialOrder', 'changeMaterial', 'orderData', 'incomingManage', 'storeroom'],
      processEnv:false,
      OtherPickUpForm: {
        receiveAddrId: 1,
        expressNo: "",
        totalWeightGram: "",
        expressCompany: "",
        phone: "",
        remark: ""
      },
      showOtherPickUp: false
    };
    },
async mounted() {
    this.de();
    const than = this;
    JieSureWattingMoney.getuser().then(res => {

      than.uerinfo = res;
      if (!this.uerinfo.forbidden) {
        //   并未认证
        Toast.fail("账户异常！");
        setTimeout(()=>{
          router.replace({
            path: "/login",
          });
        },1000)
        return false
      }
      if (res.auth === "DONE") {
        // 已经实名认证
        than.realnameBoolean = true;
      }
      if (res.auth === 'POST') {
        // 认证审核中
        than.underReview = true
      }

      localStorage.setItem("userinfo", JSON.stringify(res));
      // if (than.realnameBoolean) {
      //   // than.getWeight();
      //   // 获取金额
      //   // than.getMoney(); ggg
      // }
      if (!res.hasOwnProperty('faceVerify')) {
        // 未人脸认证
        than.isFaceAuthBox = true
      }

      this.$nextTick(()=>{
        than.isShowPage = true;
      })
    });
    this.moible = localStorage.getItem("moible");
    // 发请求,获取待处理订单数量
    let pending = await JieSureWattingMoney.noProcessing()
      this.buyMaterials = pending.buy     // 未处理买料
      this.sellMaterials = pending.sell  //未处理卖料, 目前包括存料的
      this.mortgageMaterials = pending.mortgage  // 存料
    },
  methods: {
    isAutoGraph(){
/*       if (this.underReview && !this.uerinfo.hasOwnProperty('sign')) {
              // 暂未签名
            this.$toast('未按粤恒通开户合同进行签名,请签名!')
             setTimeout(()=>{
                this.$router.push({
                name:'AccountContract',
                query:{pricing:true}
              })
              },1000)
              return false
          }*/
       if (this.uerinfo.auth === "UNPOST") {
            //   并未认证
             this.$Loading.show({text:'未实名认证'})
              setTimeout(()=>{
                this.$Loading.close()
                this.$router.push({
                name:'certification'
              })
              },1000)
              return false
          }
       if (this.uerinfo.auth === 'FAIL' ) {
            //   并未认证
              this.$Loading.show({text:'未实名认证'})

              setTimeout(()=>{
                this.$Loading.close()
                this.$router.push({
                name:'certification',
                // query:{pricing:true}
              })
              },1500)
              return false
          }
    },
    // 常用功能区 按钮事件
    linkFunciton(i){
        if (this.uerinfo.auth == 'FAIL' ||  this.uerinfo.auth == 'UNPOST') {
            //   并未认证
              this.$Loading.show({text:'未实名认证'})

              setTimeout(()=>{
                this.$Loading.close()
                this.$router.push({
                name:'certification',
                // query:{pricing:true}
              })
              },1500)
              return false
          }
        if (this.underReview) {
                // 正在认证
              this.$toast('资料审核中,此功能暂不可用!')
              return false
          }
      if (!this.realnameBoolean) {
        //   并未认证
        this.$Loading.show({text:'未实名认证'})
        setTimeout(()=>{
          this.$Loading.close()
          this.$router.push({
            name:'certification'
          })
        },1000)
        return false
      }
      if (!this.uerinfo.hasOwnProperty('sign')) {
        // 暂未签名
        this.$toast(`未按${this.GLOBAL}开户合同进行签名,请签名!`)
        setTimeout(()=>{
          this.$router.push({
            name:'AccountContract',
            query:{pricing:true}
          })
        },1000)
        return false
      }
      if (i == 6 && this.uerinfo.deposit == 0) {
        this.$toast('暂无订单数据!')
        return false
      }
           //   已认证
     this.$router.push({
        name:this.commonFunctionsList[i]
      })
      },
    // 非常用功能区 按钮事件
    link(i){

          if (this.uerinfo.auth == 'FAIL' ||  this.uerinfo.auth == 'UNPOST') {
            //   并未认证
              this.$Loading.show({text:'未实名认证'})

              setTimeout(()=>{
                this.$Loading.close()
                this.$router.push({
                name:'certification',
                // query:{pricing:true}
              })
              },1500)
              return false
          }
          if (this.underReview) {
                // 正在认证
              this.$toast('资料审核中,此功能暂不可用!')
              return false
          }
      if (!this.realnameBoolean) {
        //   并未认证
        this.$Loading.show({text:'未实名认证'})
        setTimeout(()=>{
          this.$Loading.close()
          this.$router.push({
            name:'certification'
          })
        },1500)
        return false
      }
      if (!this.uerinfo.hasOwnProperty('sign') ) {
        // 暂未签名
        this.$toast(`未按${this.GLOBAL}开户合同进行签名,请签名!`)
        setTimeout(()=>{
          this.$router.push({
            name:'AccountContract',
            query:{pricing:true}
          })
        },1000)
        return false
      }
      if (i === 11) {
        this.showOtherPickUp = true
        return
      }
           //   已认证
          this.$router.push({
                 name:this.linkList[i]
            })
    },
    // 卖料订单 待处理 数据
    de() {
      const s = 1;
      function dede() {
      }
    },
    loginout() {
      this.$router.push({ name: "login_out" });
    },
    nocer() {
      this.cershow = true;
    },
    cering() {
      this.ceringModel = true;
    },
    // 买料订单按钮点击事件
    openBuy(){
      // 如果待处理买料订单的数据为0时不跳转
      if (this.buyMaterials) {
        this.$router.push({
          name: "buyOrder",
          query: {active: 'buyJieSuan', isWaitHandle: true}
        });
      }
    },
    // 卖料订单按钮点击事件
    openSell(){
      // 如果待处理买料订单的数据为0时不跳转
      if (this.sellMaterials) {
        this.$router.push({
          name: "sellSettlement",
          query: {active: 'Settlement', isWaitHandle: true}
        });
      }
    },
    // 存料订单按钮点击事件
    openMortgage() {
      if (this.mortgageMaterials) {
        this.$router.push({
          name: "keepRecord",
          query: {active: '1', isWaitHandle: true}
        });
      }
    },
    close() {
      this.showOtherPickUp = false
      this.OtherPickUpForm.expressNo = ""
      this.OtherPickUpForm.totalWeightGram = ""
      this.OtherPickUpForm.remark = ""
      this.OtherPickUpForm.expressCompany = ""
      this.OtherPickUpForm.phone = ""
    },
    confirm() {
      if (this.OtherPickUpForm.totalWeightGram === "") {
        return this.$toast.fail("请输入货品重量"), !1;
      }
      if (!Number(this.OtherPickUpForm.totalWeightGram)) {
        return this.$toast.fail("货品重量请输入数字"), !1;
      }
      if (this.OtherPickUpForm.expressNo === "") {
        return this.$toast.fail("请输入快递单号"), !1;
      }
      this.OtherPickUpForm.expressNo = this.OtherPickUpForm.expressNo.trim()
      JieSureWattingMoney
        .expressAdd(this.OtherPickUpForm)
        .then((res) => {
          this.$toast.success({
            message: "提交成功",
            duration: 1000,
            onClose: () => {
              this.showOtherPickUp = false
              this.OtherPickUpForm.expressNo = ""
              this.OtherPickUpForm.totalWeightGram = ""
              this.OtherPickUpForm.remark = ""
              this.OtherPickUpForm.expressCompany = ""
              this.OtherPickUpForm.phone = ""
            }
          })
        })
        .catch((res) => {
          return this.$toast.fail(res.data.debug || res.data.msg || "网络错误，请稍后再试"), !1;
          // alert("网络错误，请稍后再试");
        });
    }

    },
    created(){
       process.env.NODE_ENV == 'jqt'?this.processEnv = false:this.processEnv = true
    },
  destroyed() {
    this.showOtherPickUp = false;
  },
};
</script>
<style lang="scss" scoped="true">
::v-deep{
     .van-nav-bar{
        height: 100px;
        .van-nav-bar__content{
        line-height: 100px;
        height: 100px;
        background: #F83637;
        .van-nav-bar__title{
            font-size: 36px;
            color: #fff;
        }
        .van-icon{
            font-size: 40px;
            color: #fff;
            right: 20px;
        }
        }
     }
}
.phonttop {
  position: relative;
  height: 360px;
  background: url('~@/assets/images/bg@2x.png') no-repeat;
  background-size: 100% 100%;
}
.topcenter {
  position: relative;
  top: -80px;
  border-radius: 32px;
  box-shadow: inset 0px 1px 0px 0px #FFFFFF;
  background: linear-gradient(180deg, #FDE0DC 4%, #FFFFFF 10%);
  margin: 0 30px;
    //盒子

  .box{
        width: 100%;
        //border-radius: 8px;
        //background-color: #fff;
        padding: 30px 20px 0;
        //margin-bottom: 30px;
        //box-shadow: 0px 4px 4px 3px rgba(0, 0, 0, 0.05);
        .flex{
            width: 100%;
            display: flex;
            margin-top: 32px;
            .iconBox{
                font-size: 24px;
                text-align: center;
                 flex: 1;
            }
          span {
            margin-top: 16px;
            color: #333333;
            font-size: 28px;
          }
        }
        h1{
            font-size: 36px;
            font-weight: 600;
            padding: 0 20px;
          color: #333333
        }
  }
}
.box:last-child{
  margin: 0;
}
.vanCell{
    margin-bottom: 40px;
}
.vanCell:last-child{
    margin-bottom: 0;
}
.canclebox {
  color: #c1c1c1;
  &:after {
    content: "";
    width: 2px;
    height: 70px;
    background: #c1c1c1;
    position: absolute;
    left: 50%;
    margin-left: -2px;
    top: 10px;
  }
}
.topbox {
  width: 92%;
}
.arrowbox {
  text-align: right;
  position: relative;
  // right: 0;
  top: 25px;
  flex: 1;
  i {
    // font-size: 48px;
    font-size: 40px;
  }
}
.imgminbox {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  margin-bottom: 10px;
}
.gocer {
  position: relative;
  font-size: 32px;
  padding: 20px 0;
  margin-top: 30px;
  border-top: 1px solid #ccc;
  color: #ffb606;
  text-align: center;
}
.title {
  font-size: 30px;
  text-align: center;
  margin: 20px 0;
}
.centerbox_dialog {
  padding: 0 10px;
  text-align: center;
  font-size: 28px;
}
::v-deep.van-button {
  display: none;
}
.bg_box {
  // background: red;
  background: #f7f7f7;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}
.personbox {
  z-index: 2;
  position: relative;
  overflow-y: hidden;
}
.abs {
  position: absolute;
  top: 28px;
  left: 34px;
  color: #333333;
  background: linear-gradient( 180deg, #FDE0DC 4%, #FFFFFF 100%);
  border-radius: 24px;
  padding: 10px 34px;
  .imgbox {
    border-radius: 50px;
    overflow: hidden;
    width: 100px;
    height: 100px;
  }
  .waitHandleBox {
    margin-top: 10px;
    .waitHandleDescribe {
      color: #333333;
      font-size: 28px;
      font-weight: 500;
      border-right: 2px solid #cccccc;
      width: 19%;
      line-height: 80px;
    }
    .waitHandle {
      border-right: 2px solid #cccccc;
      font-size: 28px;
      color: #666666;
      text-align: center;
      width: 27%;
      .number {
        font-size: 36px;
        color: #333333;
        font-weight: 500;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
}
.namebox {
  margin-left: 10px;
  padding-top: 10px;
  font-size: 36px;
  .phone {
    margin-top: 0px;
    font-size: 28px !important;
  }
  .postBox{
    display: flex;
    margin-bottom: 6px;
    .pleaseCertify{
      width: 140px;
    }
    p{
      margin-right: 4px;
    }
  }
}
.loading {
  width: 150px;
  height: 15px;
  margin: 0 auto;
  margin-top: 100px;
}
.loading span {
  display: inline-block;
  width: 15px;
  height: 100%;
  margin-right: 15px;
  border-radius: 50%;
  background: #f83637;
  -webkit-animation: load 1.04s ease infinite;
}
.loading span:last-child {
  margin-right: 0px;
}
.custom-title {
    line-height: inherit;
    font-size:26px;
  }

.search-icon {
    top: 9px;
    margin-right: 8px;
    font-size: 34px;
}
.guidepost-icon{
  width: 40px;
  height: 40px;
  position: relative;
  top: 8px;
  margin-right: 10px;
}
.pleaseCertify{
  width: 150px;
  height: 50px;
}
@-webkit-keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loading span:nth-child(1) {
  -webkit-animation-delay: 0.13s;
}
.loading span:nth-child(2) {
  -webkit-animation-delay: 0.26s;
}
.loading span:nth-child(3) {
  -webkit-animation-delay: 0.39s;
}
.loading span:nth-child(4) {
  -webkit-animation-delay: 0.52s;
}
.loading span:nth-child(5) {
  -webkit-animation-delay: 0.65s;
}
::v-deep {
  .van-cell {
    padding: 10px 0;
  }
  .van-field__label{
    padding-top: 8px;
    width: 180px;

  }
  .el-input__inner{
    font-size: 28px;
  }
  .el-input__suffix {
    top: -4px !important;
  }
  .el-select__caret {
    font-size: 28px !important;
  }
  .van-field__control {
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    padding-left: 16px;
  }
}
.van-dialog{
  width: 90% !important;
  padding: 0 40px 30px;
  .title{
    color: #242424;

  }
  .van-field{
    font-size: 28px;
  }
}
.title{
  font-size: 32px;
  text-align: center;
  margin: 25px 0;
  color: #242424;
}
.centerbox_dialog{
  text-align: left;
  // padding: 0 10px;
  font-size: 28px;
  .tips {
    margin-top: 14px;
    color: #ff2d2e;
    font-size: 20px;
  }
}
.topCol{
  color: #999999;
  line-height: 60px;

}
.popbtn{
  margin-top: 25px;
  div{
    margin-top: 5px!important;
    border-top: none!important;
    width: 260px;
    text-align: center;
    border-radius: 50px;
  }

}
.gocer{
  font-size: 32px;
  padding: 14px 0;
  margin-top: 30px;
  color:#fff;
  text-align: center;
}
.popbtn{
  margin-top: 25px;
  div{
    margin-top: 5px!important;
    border-top: none!important;
    width: 260px;
    text-align: center;
    border-radius: 50px;
  }

}
.closebtn{
  background-color: #D1D1D1;
}
.confirmbtn{
  background-color:#FF2D2E ;
}
</style>
